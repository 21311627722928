<!--suppress CssUnusedSymbol -->
<template>
  <div
    class="q-mt-lg relative-position overflow-hidden wrap bg-white default-rounded default-shadow row"
  >
    <div class="safe-skeleton" v-if="in_request">
      <q-skeleton width="100%" height="100%" />
    </div>
    <div
      class="column col-12 col-md-3 items-center margin-0-auto q-pt-lg min-width-x"
    >
      <avatar class="avatar-border q-mb-sm bg-white" size="150px" />
      <upload-photo />
      <div
        v-if="
          Object.keys(me_store_params).length > 0 &&
            me_store_params.show_button_favorites_in_lp.value
        "
      >
        <router-link :to="{ name: 'store' }" class="favorite_link">
          <span class="text-blue-8 text-weight-bolder">{{
            $t("me_store.add_favorites")
          }}</span>
        </router-link>
      </div>
    </div>

    <div
      class="q-ml-lg q-mb-lg q-pt-lg q-pr-lg width-fit-content-flex min-width-x"
    >
      <q-toggle class="q-mb-lg" v-model="toggle" @update:model-value="save">
        <template v-slot:default>
          <q-item-label header class="text-weight-medium">
            {{ $t("me_store.authorize_social_networks") }}
          </q-item-label>
        </template>
      </q-toggle>
      <q-list v-if="toggle">
        <q-item-label header class="text-weight-medium">
          {{ $t("me_store.maybe_u_want_to_change_your_networks") }}
        </q-item-label>
        <q-item clickable :to="{ name: 'account.social-account' }">
          <q-item-section avatar>
            <q-icon color="indigo-9" name="share" />
          </q-item-section>
          <q-item-section>{{
            $t("me_store.change_social_networks")
          }}</q-item-section>
          <q-item-section side>
            <q-item-label caption>{{ $t("me_store.shared") }}</q-item-label>
          </q-item-section>
        </q-item>
        <q-separator :spaced="true" />
      </q-list>
      <q-input
        v-if="
          Object.keys(me_store_params).length > 0 &&
            !me_store_params.is_title_read_only_in_lp.value
        "
        debounce="1000"
        :maxlength="
          Object.keys(me_store_params).length > 0
            ? me_store_params.num_limit_characters_title_lp.value
            : 30
        "
        counter
        @clear="save"
        @change="save"
        v-model="title"
        :label="$t('me_store.highlight_text')"
      >
        <template v-slot:prepend>
          <q-icon name="drive_file_rename_outline" />
        </template>
      </q-input>
      <q-input
        v-if="
          Object.keys(me_store_params).length > 0 &&
            me_store_params.is_desc_read_only_in_lp.value == 0
        "
        debounce="1000"
        :maxlength="
          Object.keys(me_store_params).length > 0
            ? me_store_params.num_limit_characters_description.value
            : 255
        "
        counter
        type="textarea"
        input-class="line-height"
        @clear="save"
        @change="save"
        v-model="description"
        :label="$t('me_store.highlight_description')"
      >
        <template v-slot:prepend>
          <q-icon name="description" />
        </template>
      </q-input>
    </div>
  </div>
</template>

<script>
import UploadPhoto from "@/modules/main/pages/account/components/uploadPhoto";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Avatar from "@/modules/main/components/avatar";
import { notifyInfo } from "@/shared/helpers/notify";
import { ref, watch, onMounted } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("me_store");

export default {
  name: "EcommPersonalization",

  components: { UploadPhoto, Avatar },

  setup() {
    const toggle = ref(false),
      title = ref(null),
      description = ref(null),
      { in_request, ecomm, me_store_params } = useGetters({
        ecomm: "getEcomm",
        in_request: "isInRequest",
        me_store_params: "getMeStoreParams",
      }),
      { storeEcomm, fetchParametersByKeys } = useActions([
        "storeEcomm",
        "fetchParametersByKeys",
      ]);

    const setValues = () => {
      if (Object.entries(ecomm.value).length === 0) return;

      const {
        highlight_text,
        highlight_description,
        show_networks,
      } = ecomm.value;

      title.value = highlight_text;
      description.value = highlight_description;
      toggle.value = !!show_networks;
    };

    watch(in_request, () => {
      if (in_request.value === false) setValues();
    });

    const save = () => {
      storeEcomm({
        highlight_text: title.value,
        highlight_description: description.value,
        show_networks: toggle.value,
      }).catch(() => {
        notifyInfo("notify.unprocessed_error_on_save");
      });
    };

    onMounted(() => {
      setValues();
      fetchParametersByKeys({
        keys: [
          "num_limit_characters_description",
          "num_limit_characters_title_lp",
          "is_title_read_only_in_lp",
          "is_desc_read_only_in_lp",
          "show_button_favorites_in_lp",
        ],
      });
    });

    return {
      me_store_params,
      title,
      description,
      in_request,
      ecomm,
      toggle,
      save,
    };
  },
};
</script>

<style scoped>
.safe-skeleton {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 6;
  opacity: 0.9;
}
.favorite_link {
  text-decoration: none;
}
input.line-height {
  line-height: 2 !important;
  resize: none !important;
  max-height: 130px !important;
}
</style>
