<template>
  <div class="row justify-center">
    <div class="q-mt-lg card-carrousel-content">
      <choose-image-component
        :data="getAssets.data"
        :last_page="getAssets.last_page"
        :selectedTotal="photos.length || [].length"
        :photos="photos"
      />
      <div
        v-if="in_request === false && closeBoxes === false"
        class="row q-col-gutter-xs justify-center"
      >
        <div
          class="col-4 card-carrousel-width"
          v-for="index in max_upload"
          :key="`image-upload-${index - 1}`"
        >
          <image-up-loader
            :image="photos[index - 1] || {}"
            :config="config"
            @image-uploaded="onUploadedImage"
            @image-deleted="onDeleteImage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpLoader from "@/modules/main/pages/me-store/components/imageUpLoader";
import ChooseImageComponent from "./ChooseImageComponent";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyInfo } from "@/shared/helpers/notify";
import { ref, nextTick, onMounted } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("me_store");

export default {
  name: "ImagesBoxes",

  components: { ImageUpLoader, ChooseImageComponent },

  setup() {
    const { photos, in_request, max_upload, config, getAssets } = useGetters({
        max_upload: "getPhotoMaxUpload",
        photos: "getPhotos",
        config: "getImageUploadConf",
        in_request: "isInRequest",
        getAssets: "getAssets",
      }),
      closeBoxes = ref(false),
      modalView = ref(false);

    const { pushNewImage, deleteEcommPhoto, fetchPartnerStoreAssets } =
        useActions([
          "pushNewImage",
          "deleteEcommPhoto",
          "fetchPartnerStoreAssets",
        ]),
      onUploadedImage = (photo) => {
        pushNewImage(photo);
        nextTick(() => (closeBoxes.value = false));
      },
      onDeleteImage = (id) => {
        deleteEcommPhoto({ id: id })
          .catch(() => notifyInfo("notify.unprocessed_error_on_delete"))
          .finally(() => {
            closeBoxes.value = true;
            nextTick(() => (closeBoxes.value = false));
          });
      };

    onMounted(() =>
      fetchPartnerStoreAssets({ page: 1, target: "partner_store" })
    );

    return {
      modalView,
      getAssets,
      max_upload,
      closeBoxes,
      config,
      in_request,
      photos,
      onDeleteImage,
      onUploadedImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-carrousel-width {
  width: 200px;
  height: 300px;
}

.desktop {
  .card-carrousel-width {
    margin: 10px 15px;
  }
}

.mobile {
  .card-carrousel-width {
    width: calc(200px / 2);
    height: calc(300px / 2);
  }
}
</style>
