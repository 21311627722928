<template>
  <q-card class="q-pa-xs q-ma-sm" style="border-radius: 10px">
    <q-img height="250px" class="image" :src="image.value">
      <div
        v-if="image.expire_in"
        class="actions no-padding no-margin bg-transparent absolute-top-left"
      >
        <q-badge
          color="grey-9"
          class="q-pa-md"
          style="opacity: 0.7; width:100% !important"
        >
          <b>
            {{
              $t("library_assets.me_store.expire_in", {
                date: formatDate(image.expire_in),
              })
            }}
          </b>
        </q-badge>
      </div>
      <div
        class="actions no-padding no-margin bg-transparent absolute-bottom-right"
      >
        <q-btn
          round
          size="md"
          class="q-ma-xs"
          color="indigo-11"
          icon="add"
          :loading="loading"
          v-if="!verifyImage(image.value)"
          @click="selectImage(image)"
        />
        <q-btn
          round
          size="md"
          class="q-ma-xs"
          color="green"
          icon="check"
          v-else
        />
      </div>
    </q-img>
  </q-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { baseURL } from "@/shared/http/api";
import { useI18n } from "vue-i18n";
import { Notify } from "quasar";
import moment from "moment";
import axios from "axios";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("me_store");

export default {
  name: "CardImageComponent",

  props: {
    image: {
      type: Object,
      default: () => ({}),
    },
    selectedTotal: {
      type: Number,
      default: 0,
    },
    photos: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const { t } = useI18n(),
      loading = ref(false),
      all_images = ref([]),
      { fetchSelectLibraryImage, setEcommData } = useActions([
        "fetchSelectLibraryImage",
        "setEcommData",
      ]),
      authNamespace = createNamespacedHelpers("auth"),
      { token } = authNamespace.useState(["token"]);

    const getAllImages = () => {
        all_images.value = props.photos.map((image) => image.full_url);
      },
      verifyImage = (image) => all_images.value.includes(image);

    getAllImages();

    const selectImage = (image) => {
        loading.value = !loading.value;
        if (props.selectedTotal >= 5) {
          notify(t("library_assets.me_store.total_exceeded"), "red-4");
          return;
        }

        fetchSelectLibraryImage({ photo: image.value, asset_id: image.id })
          .then(() => {
            setTimeout(() => {
              fetchEcomm().then((resources) => {
                notify(t("library_assets.me_store.success"), "blue");
                setEcommData(resources.data).then(() => {
                  getAllImages();
                });
              });
            }, 1000);
          })
          .catch(() => {
            notify(t("library_assets.me_store.fail"), "red");
          });
      },
      fetchEcomm = () => {
        const conf = {
          url: `${baseURL}/api/v1/ecomm-store`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token.value}`,
          },
        };
        return axios(conf);
      },
      notify = (message, color) => {
        Notify.create({
          message,
          color,
          position: "top-right",
        });
        loading.value = !loading.value;
      };

    return {
      loading,
      all_images,
      verifyImage,
      selectImage,
      formatDate: (date) => moment(date).format("DD/MM/YYYY"),
    };
  },
};
</script>

<style scoped lang="scss">
.image {
  cursor: pointer;
}

.image:hover {
  opacity: 0.9;
  transition: 0.4s;
}
</style>
