<template>
  <page-title
    :title="$t('me_store.title')"
    video_url="https://www.youtube.com/embed/J9PgIancf1w"
  />
  <ecomm-personalization />
  <div
    v-if="can_add_photo && description"
    class="bg-white text-ds-grey default-rounded default-card-border q-pa-md q-mt-md"
    v-html="description"
  ></div>
  <h5
    v-if="can_add_photo && title"
    class="text-h5 text-blue-grey-10 q-mb-none text-center"
    v-html="title"
  ></h5>
  <images-boxes v-if="can_add_photo" />
</template>

<script>
import EcommPersonalization from "@/modules/main/pages/me-store/components/ecommPersonalization";
import ImagesBoxes from "@/modules/main/pages/me-store/components/imagesBoxes";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import PageTitle from "@/modules/main/components/page-title";

import { ref, onMounted } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("me_store");

export default {
  name: "MeStore",

  components: { ImagesBoxes, EcommPersonalization, PageTitle },

  setup() {
    const toggle = ref(false),
      { fetchEcomm } = useActions(["fetchEcomm"]),
      { can_add_photo, description, title } = useGetters({
        can_add_photo: "isCanAddPhoto",
        description: "getHelpDescription",
        title: "getTitle",
      });

    onMounted(() => {
      fetchEcomm();
    });

    return {
      toggle,
      description,
      title,
      can_add_photo,
    };
  },
};
</script>

<style lang="scss">
@media (max-width: 760px) {
  .vicp-wrap {
    width: 80% !important;
    display: flex !important;
  }
}
</style>
