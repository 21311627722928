<template>
  <div class="row" v-if="data.length">
    <div class="q-ml-auto q-mr-auto q-mb-md">
      <q-btn
        :label="$t('library_assets.me_store.image_library')"
        color="white"
        class="bg-black"
        icon="photo_library"
        @click="modalView = true"
      />
    </div>
  </div>
  <hr />
  <q-dialog
    v-model="modalView"
    transition-show="slide-up"
    transition-hide="slide-down"
  >
    <q-card style="width: 770px; max-width: 80vw;">
      <q-bar class="bg-black text-white">
        <q-icon name="photo_library" />
        <div class="text-bar">
          {{ $t("library_assets.me_store.image_library") }}
        </div>
        <q-space />
        <q-btn dense flat icon="close" v-close-popup>
          <q-tooltip>{{ $t("global.close") }}</q-tooltip>
        </q-btn>
      </q-bar>

      <q-card-section class="bg-grey-4 ">
        <div
          class="q-pa-lg bg-white q-mr-md q-ml-md text-justify"
          style="border-radius: 10px; font-size: 9pt"
          v-html="$t('library_assets.me_store.message')"
          v-if="data.length"
        ></div>
        <div class="row q-ma-sm">
          <div
            class="col-12 col-sm-3 "
            v-for="(image, i) in data"
            :key="`img-${i}`"
          >
            <card-image-component
              :image="image"
              :photos="photos"
              :selectedTotal="selectedTotal"
            />
          </div>
        </div>
        <div class="q-pa-lg flex q-mt-lg flex-center" v-if="data.length">
          <q-pagination
            color="black"
            :max="last_page"
            v-model="current"
            direction-links
            boundary-numbers
          />
        </div>
        <div v-else class="bg-grey-5 text-black text-center message">
          {{ $t("library_assets.me_store.no_images") }}
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import CardImageComponent from "./CardImageComponent.vue";
import { ref, watch } from "vue";

const { useActions } = createNamespacedHelpers("me_store");


export default {
  name: "ChooseImageComponent",

  components: {
    CardImageComponent,
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    last_page: {
      type: Number,
      default: 1,
    },
    selectedTotal: {
      type: Number,
      default: 0,
    },
    photos: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const modalView = ref(false),
      searchText = ref(""),
      current = ref(1),
      { fetchPartnerStoreAssets } = useActions(["fetchPartnerStoreAssets"]);

    watch(current, (currentValue) => {
      fetchPartnerStoreAssets({ page: currentValue, target: 'partner_store'} );
    });

    return {
      searchText,
      modalView,
      current,
    };
  },
};
</script>

<style scoped lang="scss">
.message {
  padding: 150px;
  border-radius: 10px;
  font-size: 14pt;
}
</style>
