<template>
  <div
    class="full-height full-width default-rounded default-shadow q-pa-xs bg-white"
  >
    <my-upload
      field="photo"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      lang-type="pt-br"
      :width="300"
      :height="300"
      :params="params"
      :headers="config.headers"
      :url="config.url"
      img-format="jpg"
    ></my-upload>
    <q-img
      @error="hasImage = false"
      class="default-rounded"
      :src="verifyImages(image)"
      width="100%"
      height="100%"
      id="teste"
    >
      <div
        class="loading-display row items-center justify-center "
        v-if="onLoading"
      >
        <q-linear-progress
          indeterminate
          color="grey"
          size="10px"
          class="absolute-top"
        />
        <q-linear-progress
          indeterminate
          color="grey"
          size="10px"
          class="absolute-bottom"
        />
        <q-spinner color="grey" size="5em" :thickness="2" />
      </div>
      <div v-else-if="!hasImage" class="card-image"></div>
      <div
        class="actions no-padding no-margin bg-transparent absolute-bottom-right"
      >
        <q-btn
          v-if="hasImage"
          @click="deleteClick"
          :size="$q.platform.is.mobile ? 'xs' : 'md'"
          class="q-ma-xs"
          round
          color="pink-5"
          icon="delete"
        />
        <q-btn
          v-else
          @click="toggleShow"
          :size="$q.platform.is.mobile ? 'xs' : 'md'"
          class="q-ma-xs"
          round
          color="indigo-11"
          icon="add"
        />
      </div>
    </q-img>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import myUpload from "vue-image-crop-upload";
import { ref, computed } from "vue";
// import $ from "jquery";

const { useState } = createNamespacedHelpers("auth");

export default {
  name: "ImageUpLoader",

  components: { myUpload },

  props: {
    image: {
      type: Object,
      require: true,
    },
    config: {
      type: Object,
      require: true,
    },
  },

  emits: ["imageUploaded", "imageDeleted"],

  setup(props, { emit }) {
    const show = ref(false),
      hasImage = computed(() => !!props.image.id),
      { token } = useState(["token"]),
      onLoading = ref(false);

    const verifyImages = (image) => {
      if (image.full_url) {
        return image.full_url
          ? image.full_url
          : "/awards-icons/img_not_found.png";
      }
    };

    /**
     * verify if image exists on S3 and return status
     * @param {string} imageUrl
    const checkStatus = (imageUrl) => {
      const http = $.ajax({
        type: "GET",
        url: imageUrl,
        async: false,
      });
      return http.status;
    };     
    */

    return {
      show,
      token,
      onLoading,
      hasImage,
      verifyImages,
      cropUploadFail() {},
      toggleShow() {
        show.value = !show.value;
      },
      cropUploadSuccess(photo) {
        emit("imageUploaded", photo);
      },
      deleteClick() {
        onLoading.value = true;
        emit("imageDeleted", props.image.id);
      },
    };
  },
};
</script>

<style lang="scss">
.card-image,
.loading-display {
  height: 100%;
  width: 100%;
  background-color: #d9d9d9 !important;
}
</style>
